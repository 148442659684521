import React from "react";
import Layout from "../components/layout";

const TestTourney = () => {
  //Team count needs to be greater than 3 or else it's a waste of my time
  const teamCount = 11;
  const nearestPowerOf2 =
    NearestPowerOf2(teamCount) < teamCount
      ? NearestPowerOf2(teamCount) * 2
      : NearestPowerOf2(teamCount);
  const totalByes = nearestPowerOf2 - teamCount;
  // console.log("total byes: ", totalByes);
  const teamArray = Array.from(Array(teamCount + totalByes), (_, i) => i + 1);
  // console.log("teamArray: ", teamArray);
  function NearestPowerOf2(n) {
    return 1 << (31 - Math.clz32(n));
  }
  const num_rounds = Math.log(teamArray.length) / Math.log(2);
  // console.log("num_rounds: ", num_rounds);

  //2-dimensional array, each subarray holds the seeds active in that round, in order of matches played
  //example: seeds in first match of 2nd round are: seed_round[1][0] & seed_round[1][1]
  let seed_rounds = [];

  //messy here, just creates empty arrays inside seed_round
  for (let i = 0; i < num_rounds; i++) {
    seed_rounds[i] = [];
  }
  //assuming no upsets in the bracket, 1st and 2nd seeds should be in the final match
  seed_rounds[num_rounds] = [1, 2];

  //for each round in the bracket
  for (let r = num_rounds; r > 0; r--) {
    let round = seed_rounds[r];
    let feed_round = seed_rounds[r - 1];

    //for each seed in the round, work out the who they defeated in the previous round, storing the resil
    for (let m = 0; m < round.length; m++) {
      let num_teams_in_round = round.length * 2;
      //feeder match seed A = current match seed "m"
      feed_round[m * 2] = round[m];

      //feeder match seed B = # teams in feeder round plus 1 minus current match seed "m"
      feed_round[m * 2 + 1] = num_teams_in_round + 1 - round[m];
    }
  }
  // console.log("seed rounds: ", seed_rounds);
  const correctRoundIndex = seed_rounds.findIndex(
    (element) => element.length === teamArray.length
  );
  // console.log("seeds correctRoundIndex:", seed_rounds[correctRoundIndex]);
  const seedsWithByes = seed_rounds[correctRoundIndex].map((seed) => {
    // console.log("seed: ", seed);
    return seed <= teamCount ? seed : "BYE";
  });
  // console.log("seedsWithByes: ", seedsWithByes);
  const round1PairedSeeds = [];
  for (let i = 0; i < seedsWithByes.length; i += 2) {
    round1PairedSeeds.push([seedsWithByes[i], seedsWithByes[i + 1]]);
  }
  // console.log("first round seed pairs: ", round1PairedSeeds);

  //MAP ALL WINNERS/LOSERS ROUNDS BY SEED # WINNERS AND LOSERS. THEN PLACE IN BRACKET TO LATER FIGURE OUT ORDER OF MATCHES AND WHERE LOSERS GO IN LOSER BRACKET
  let winnersBracketMapped = [];
  let losersBracketMapped = [];
  const actual_seed_rounds = seed_rounds.splice(correctRoundIndex);
  // console.log("actual_seed_rounds: ", actual_seed_rounds);
  actual_seed_rounds.forEach((round, index) => {
    // console.log("round: ", round);
    let winnersRound = [];
    let losersRound = [];
    const pairedSeeds = [];
    //Pair the original seeds together so we can compare them and send to correct winners/losers round
    for (let i = 0; i < round.length; i += 2) {
      pairedSeeds.push([round[i], round[i + 1]]);
    }
    //Push the winner to the winners round and loser to the losers round
    pairedSeeds.forEach((pair) => {
      winnersRound.push(pair[0]);
      losersRound.push(pair[1]);
    });
    // console.log("winnersRound: ", winnersRound);
    // Map the seeds and create BYE's if seed is over the team total
    const winnersRoundWithByes = winnersRound.map((seed) => {
      return seed <= teamCount ? seed : "BYE";
    });
    const losersRoundWithByes = losersRound.map((seed) => {
      return seed <= teamCount ? seed : "BYE";
    });
    const winnersPaired = [];
    const losersPaired = [];
    //Pair the round back up so we can push to the final winners/losers bracket mapped array
    for (let i = 0; i < winnersRoundWithByes.length; i += 2) {
      winnersPaired.push([
        winnersRoundWithByes[i],
        winnersRoundWithByes[i + 1],
      ]);
    }
    for (let i = 0; i < losersRoundWithByes.length; i += 2) {
      losersPaired.push([losersRoundWithByes[i], losersRoundWithByes[i + 1]]);
    }
    winnersBracketMapped.push(winnersPaired);
    losersBracketMapped.push(losersPaired);
  });

  //ADD EXTRA ROUNDS TO LOSERS BRACKET. IF ROUND IS COMPLETELY FULL OF MATCHES, DUPLICATE AND MAKE ALL EMPTY SLOTS.
  //This is not always the case for the first round of the losers bracket. For instance, if it's 16 or 15 teams, things get wonky and the second round generated by our formula needs to be merged with part of the first round of the losers bracket in some way. Check out 15 and 16 team brackets on challonge to see this compared to our bracket generated.
  //UPON FURTHER REVIEW AFTER THE NOTE ABOVE: Round 2 losers needs to be flipped (reverse the array - as the round normally would), then split each pre-defined slot into it's own match. E.G.: 16 or 15 teams - (8v5) and (7v6) becomes (6v previous winner) (7vPW) (5vPW) (8vPW)
  //Since we now know which matchups are supposed to happen in each round, we can use our auto generated empty brackets (generated below) and now start deciding which slots each loser gets placed into
  //Our First two generated and seeded losers rounds are always going to stay in that place. If there's BYE games in the first round, that seed needs to move to the second round and placed into match against the already reversed seeded array of 2nd rounders.

  //CREATE SUBSEQUENT ROUND MATCHUP SLOTS
  let winnersBracketMatchups = [round1PairedSeeds];
  let previousRoundMatchups = round1PairedSeeds.length;
  // console.log("previousRoundMatchups: ", previousRoundMatchups);
  while (previousRoundMatchups > 1) {
    let nextRoundMatchups = Array.from(
      Array(previousRoundMatchups / 2),
      (_, i) => ["EMPTY", "EMPTY"]
    );
    winnersBracketMatchups.push(nextRoundMatchups);
    previousRoundMatchups = nextRoundMatchups.length;
  }

  //ADVANCE ROUND 1 BYE MATCHUPS AND MARK MATCHUP TO BE FADED
  winnersBracketMatchups[0].forEach((matchup, index) => {
    if (matchup.includes("BYE")) {
      const nonByeIndex = matchup.findIndex((e) => e !== "BYE");
      const secondRoundIndex = Math.round((index + 1) / 2);
      const isSecondRoundIndexInteger = Number.isInteger(index / 2);
      winnersBracketMatchups[1][secondRoundIndex - 1][
        isSecondRoundIndexInteger ? 0 : 1
      ] = matchup[nonByeIndex];
    }
  });

  // ADD LOSERS BRACKET MATCHES
  let losersBracketRound1 = [];
  let losersBracketMatchups = [];
  //Put all first round "losers" into single array
  winnersBracketMatchups[0].forEach((matchup, index) => {
    if (matchup.includes("BYE")) {
      losersBracketRound1.push("BYE");
    } else {
      losersBracketRound1.push(Math.max(...matchup));
    }
  });
  //Pair each "Losers" matchup into 2 team arrays to get first losers round output
  let losersRound1PairedSeeds = [];
  for (let i = 0; i < losersBracketRound1.length; i += 2) {
    losersRound1PairedSeeds.push([
      losersBracketRound1[i],
      losersBracketRound1[i + 1],
    ]);
  }
  losersBracketMatchups.push(losersRound1PairedSeeds);
  //Figure out which is the true winners first round (most M# that can be played)
  function FindActualMatchupTotal(arr) {
    let total = 0;
    arr.forEach((matchup) => {
      if (!matchup.includes("BYE")) {
        total = total + 1;
      }
    });
    return total;
  }
  const winnersFirstRoundActualMatchups = FindActualMatchupTotal(
    winnersBracketMatchups[0]
  );
  const winnersSecondRoundActualMatchups = FindActualMatchupTotal(
    winnersBracketMatchups[1]
  );
  // Figure out second round matchups:
  // If the winners second round matchups = Power of 2(8, 16, 32, etc.): This will equal that.
  // If not: figure out which round in winners is larger(1 or 2) and half it then round it to get your 2nd round losers total.
  const losersSecondRoundTotalMatchups =
    winnersSecondRoundActualMatchups ===
    NearestPowerOf2(winnersSecondRoundActualMatchups)
      ? winnersSecondRoundActualMatchups
      : winnersFirstRoundActualMatchups > winnersSecondRoundActualMatchups
      ? Math.round(winnersFirstRoundActualMatchups / 2)
      : Math.round(winnersSecondRoundActualMatchups / 2);
  const losersBracketRound2 = Array.from(
    Array(losersSecondRoundTotalMatchups),
    (_, i) => ["EMPTY", "EMPTY"]
  );
  losersBracketMatchups.push(losersBracketRound2);
  // Create remaining Losers Bracket rounds
  let continueBuildingLosersRounds = true;
  while (continueBuildingLosersRounds) {
    const previous2LosersRounds = losersBracketMatchups.slice(
      Math.max(losersBracketMatchups.length - 2, 0)
    );
    if (
      previous2LosersRounds[0].length === previous2LosersRounds[1].length &&
      previous2LosersRounds[1].length === 1
    ) {
      //If the last two losers rounds are equal in length and only have 1 matchup each: Quit this while loop and done building Losers Bracket
      continueBuildingLosersRounds = false;
    } else if (
      previous2LosersRounds[0].length === previous2LosersRounds[1].length &&
      previous2LosersRounds[1].length > 1
    ) {
      //If the last two losers rounds are equal in length and have MORE than 1 matchup each: divide the last round into 2 and add it to the losersBracketMatchups array
      losersBracketMatchups.push(
        Array.from(Array(previous2LosersRounds[1].length / 2), (_, i) => [
          "EMPTY",
          "EMPTY",
        ])
      );
    } else if (
      previous2LosersRounds[0].length !== previous2LosersRounds[1].length
    ) {
      //If the last two losers rounds are NOT equal in length: duplicate the last losers round and add it to the losersBracketMatchups array
      losersBracketMatchups.push(
        Array.from(Array(previous2LosersRounds[1].length), (_, i) => [
          "EMPTY",
          "EMPTY",
        ])
      );
    }
  }

  //If Winners R1 has BYES, check if Winners R2 has any empty vs. empty slots. If so, Losers round 1 should immediately follow Winners round 1
  let shouldLosersR1FollowWinnersR1 = false;
  if (totalByes > 0) {
    shouldLosersR1FollowWinnersR1 = winnersBracketMatchups[1].some(
      (matchup) =>
        JSON.stringify(matchup) === JSON.stringify(["EMPTY", "EMPTY"])
    );
  }
  if (teamCount === nearestPowerOf2) shouldLosersR1FollowWinnersR1 = true;

  //PLACE losersBracketMapped into losersBracketMatchups array, correctly slotted
  //If every single losers round 1 matchup is a BYE, split round 2 into 2 rounds (evenly) and place half of the lowest (numerically) seeds from the generated round 2 into round 1 and regroup (highest plays lowest), then flip the new round 2 array backwards as normal.
  //20 and 45 Teams are great to test with this to make sure it's working
  let losersBracketFinalMatchupsAutoMapped = [];
  losersBracketMapped.forEach((round, index) => {
    const roundArray =
      losersBracketFinalMatchupsAutoMapped.length > 0
        ? losersBracketFinalMatchupsAutoMapped[
            losersBracketFinalMatchupsAutoMapped.length - 1
          ]
        : round;
    // console.log("roundArray: ", roundArray, index);
    if (roundArray.every((matchup) => matchup.includes("BYE"))) {
      //Filter out matches that contain only BYE's
      let newRound = roundArray;
      //Get the lowest seeds from the next losers round
      let nextRoundLowestSeeds = [];
      let nextRoundHighestSeeds = [];
      let nextRoundAllSeeds = [];
      //Figure out if the current round total matchups count is more than the next round. If so, combine all matchups into single array rather than split into higher/lower
      const useAllSeeds =
        losersBracketMapped[index + 1].length !== newRound.length
          ? true
          : false;
      losersBracketMapped[index + 1].forEach((matchup) => {
        if (useAllSeeds) {
          nextRoundAllSeeds.push(...matchup);
        } else {
          nextRoundLowestSeeds.push(Math.min(...matchup));
          nextRoundHighestSeeds.push(Math.max(...matchup));
        }
      });
      nextRoundLowestSeeds.reverse();
      nextRoundAllSeeds.reverse();
      //Reseed the losers round with lowest seeds of following round
      let newRoundSeeded = newRound.map((match, index) => {
        return [
          useAllSeeds ? nextRoundAllSeeds[index] : nextRoundLowestSeeds[index],
          match[1],
        ];
      });
      let nextRoundSeeded = nextRoundHighestSeeds.map((match) => {
        return ["BYE", match];
      });
      losersBracketFinalMatchupsAutoMapped.push(newRoundSeeded);
      if (!useAllSeeds) {
        losersBracketFinalMatchupsAutoMapped.push(nextRoundSeeded.reverse());
      }
    } else {
      let currentRoundMatchups = round.map((matchup) => ["EMPTY", "EMPTY"]);
      const currentRoundReversed = round.reverse().map((matchup) => {
        return matchup.reverse();
      });
      const nextRound = losersBracketMapped[index + 1];
      let roundSeeds = [];
      let newSeededMatchups;
      if (nextRound) {
        nextRound.forEach((seed) => roundSeeds.push(...seed));
        if (roundSeeds.includes(undefined)) {
          newSeededMatchups = nextRound;
        } else {
          newSeededMatchups = roundSeeds.map((seed) => {
            return ["EMPTY", seed];
          });
        }
        losersBracketFinalMatchupsAutoMapped.push(currentRoundMatchups);
        losersBracketFinalMatchupsAutoMapped.push(newSeededMatchups);
      }
    }
  });

  function AdvanceLosersRound1Games() {
    losersBracketFinalMatchupsAutoMapped[1].reverse();
    losersBracketFinalMatchupsAutoMapped[0].forEach((matchup, matchIndex) => {
      if (matchup.includes("BYE")) {
        const indexToReplace = losersBracketFinalMatchupsAutoMapped[1][
          matchIndex
        ].findIndex((item) => item.includes("EMPTY"));
        losersBracketFinalMatchupsAutoMapped[1][matchIndex][indexToReplace] =
          matchup[1];
      }
    });
  }

  if (losersBracketFinalMatchupsAutoMapped[0][0].includes("EMPTY")) {
    losersBracketFinalMatchupsAutoMapped[0] = losersBracketMatchups[0];
    AdvanceLosersRound1Games();
  } else {
    losersBracketFinalMatchupsAutoMapped[1] = losersBracketMapped[1];
  }

  //ADD MATCH OBJECT INFO TO ALL MATHCES WINNERS AND LOSERS
  // Final losers bracket to base this off of is: losersBracketFinalMatchupsAutoMapped
  // Figure out first 2 round of Winners and Losers Match #'s
  let matchNumber = 1;
  let currentLosersRoundIndex = 0;
  let currentMatchIndex = 1;
  const winnersBracketMatchupsAsObjects = winnersBracketMatchups.map(
    (round, roundIndex) => {
      const totalRoundMatches = round.length;
      const matchesWithBye = round.filter((matchup) =>
        matchup.includes("EMPTY")
      );
      const losersRound1TotalMatches = losersBracketFinalMatchupsAutoMapped[0].filter(
        (matchup) => !matchup.includes("BYE")
      ).length;
      const doesLosersRound1ContainABye = losersBracketFinalMatchupsAutoMapped[0].some(
        (matchup) => matchup.includes("BYE")
      );
      const isLosersMatchingRoundAllEmpty = losersBracketFinalMatchupsAutoMapped[
        currentLosersRoundIndex
      ].every((matchup) => matchup.every((seed) => seed === "EMPTY"));
      const precedingLoserRoundMatchupTotal =
        roundIndex === 1
          ? losersBracketFinalMatchupsAutoMapped[0].length
          : roundIndex > 1
          ? losersBracketFinalMatchupsAutoMapped[currentLosersRoundIndex - 1]
              .length
          : null;
      const matchingLoserRoundMatchupTotal =
        losersBracketFinalMatchupsAutoMapped[currentLosersRoundIndex].length;
      const losersRoundIndex = currentLosersRoundIndex;
      const isCurrentLosersRoundIndexLast =
        currentLosersRoundIndex ===
        losersBracketFinalMatchupsAutoMapped.length - 1;
      const followingLoserRoundMatchupTotal = isCurrentLosersRoundIndexLast
        ? 0
        : losersBracketFinalMatchupsAutoMapped[currentLosersRoundIndex + 1]
            .length;
      let lastMatchupNumberWithEmptySlotInRound2;
      const matches = round.map((matchup, matchIndex) => {
        let hasMatchNumberBeenUpdated = false;
        const isMatchupCompletelyEmpty = matchup.every(
          (seed) => seed === "EMPTY"
        );
        let matchupObj = {
          matchID: currentMatchIndex,
          round: roundIndex + 1,
          bracket: 0,
          matchNumber: matchup.includes("BYE") ? null : matchNumber,
          team1ID:
            roundIndex === 0
              ? matchup[0]
              : winnersBracketMapped[roundIndex - 1][matchIndex][0],
          team2ID:
            roundIndex === 0
              ? matchup[1]
              : winnersBracketMapped[roundIndex - 1][matchIndex][1],
          team1Name:
            roundIndex === 0
              ? matchup[0]
              : winnersBracketMapped[roundIndex - 1][matchIndex][0],
          team2Name:
            roundIndex === 0
              ? matchup[1]
              : winnersBracketMapped[roundIndex - 1][matchIndex][1],
          team1Scores: [0],
          team2Scores: [0],
          team1Players: matchup[0],
          team2Players: matchup[1],
          winnerID: null,
          winnerName: null,
          loserID: null,
          loserName: null,
          winnerNextMatchID: null,
          loserNextMatchID: null,
          status: 0,
        };
        const filterOutSecondRoundNonEmptySlots = winnersBracketMatchups[1].filter(
          (m) => m[0] === "EMPTY"
        );
        const isFirstRoundCompletelyFull =
          filterOutSecondRoundNonEmptySlots.length > 0 ? false : true;
        if (
          roundIndex === 1 &&
          !shouldLosersR1FollowWinnersR1 &&
          !isCurrentLosersRoundIndexLast
        ) {
          const matchWithByeIndex = matchesWithBye.findIndex(
            (match) => match === matchup
          );
          const matchNumberForBye = totalRoundMatches + (matchWithByeIndex + 1);
          matchupObj.matchNumber =
            matchup.includes("EMPTY") && !isMatchupCompletelyEmpty
              ? matchNumberForBye
              : matchNumber;

          if (matchWithByeIndex === matchesWithBye.length - 1) {
            lastMatchupNumberWithEmptySlotInRound2 = matchupObj.matchNumber;
          }
          if (matchIndex === round.length - 1) {
            //Find last matchup that has empty player and set match number to equal it's match number
            matchNumber = lastMatchupNumberWithEmptySlotInRound2;
            if (matchWithByeIndex === matchesWithBye.length - 1) {
              matchNumber = matchNumber + 1;
            }
          }

          currentLosersRoundIndex = 2;
        }
        if (
          roundIndex === 1 &&
          shouldLosersR1FollowWinnersR1 &&
          matchIndex === 0 &&
          !isCurrentLosersRoundIndexLast
        ) {
          matchNumber =
            matchNumber +
            losersBracketFinalMatchupsAutoMapped[0].filter(
              (matchup) => !matchup.includes("BYE")
            ).length;
          matchupObj.matchNumber = matchNumber;
          matchNumber = matchNumber + 1;
          hasMatchNumberBeenUpdated = true;
          currentLosersRoundIndex = 2;
        } else if (
          roundIndex === 1 &&
          shouldLosersR1FollowWinnersR1 &&
          matchIndex > 0 &&
          !isCurrentLosersRoundIndexLast
        ) {
          matchupObj.matchNumber = matchNumber;
          if (matchup.includes("EMPTY") && !isMatchupCompletelyEmpty) {
            matchNumber = matchNumber + 1;
          }
          currentLosersRoundIndex = 2;
        }
        if (
          roundIndex > 1 &&
          roundIndex !== winnersBracketMatchups.length - 1
        ) {
          if (isLosersMatchingRoundAllEmpty && matchIndex === 0) {
            matchNumber =
              matchNumber +
              precedingLoserRoundMatchupTotal +
              matchingLoserRoundMatchupTotal;
            currentLosersRoundIndex = currentLosersRoundIndex + 1;
          } else if (
            !isLosersMatchingRoundAllEmpty &&
            matchIndex === 0 &&
            roundIndex !== 2
          ) {
            matchNumber =
              matchNumber +
              precedingLoserRoundMatchupTotal +
              followingLoserRoundMatchupTotal;
            currentLosersRoundIndex = currentLosersRoundIndex + 2;
          } else if (
            roundIndex === 2 &&
            !shouldLosersR1FollowWinnersR1 &&
            roundIndex !== winnersBracketMatchups.length - 1 &&
            matchIndex === 0 &&
            (doesLosersRound1ContainABye || isFirstRoundCompletelyFull)
          ) {
            matchNumber =
              matchNumber +
              losersRound1TotalMatches +
              precedingLoserRoundMatchupTotal;
            currentLosersRoundIndex = 3;
          }
          matchupObj.matchNumber = matchNumber;
        }
        if (
          (roundIndex > 1 &&
            roundIndex === winnersBracketMatchups.length - 1) ||
          (isCurrentLosersRoundIndexLast && roundIndex === 1)
        ) {
          matchupObj.matchNumber = matchNumber + 3;
        } else if (
          ((!matchup.includes("BYE") && !matchup.includes("EMPTY")) ||
            isMatchupCompletelyEmpty) &&
          !hasMatchNumberBeenUpdated
        ) {
          matchNumber = matchNumber + 1;
        }
        currentMatchIndex = currentMatchIndex + 1;

        return matchupObj;
      });
      const roundObj = {
        losersRoundIndex: losersRoundIndex,
        matches,
      };
      return roundObj;
    }
  );

  let winnersArrayFlat = [];
  winnersBracketMatchupsAsObjects.forEach((round) => {
    winnersArrayFlat.push(...round.matches);
  });

  function CreateLosersBracketInfoArray() {
    const losersBracketMatchupsAsObjectsArray = losersBracketFinalMatchupsAutoMapped.map(
      (round, roundIndex) => {
        const matches = round.map((matchup, matchIndex) => {
          let matchupObj = {
            matchID: currentMatchIndex,
            round: roundIndex + 1,
            bracket: 1,
            matchNumber: null,
            team1ID: matchup[0],
            team2ID: matchup[1],
            team1Name: matchup[0],
            team2Name: matchup[1],
            team1Scores: [0],
            team2Scores: [0],
            team1LoserOf: 0,
            team2LoserOf: 0,
            team1Players: matchup[0],
            team2Players: matchup[1],
            winnerID: null,
            winnerName: null,
            loserID: null,
            loserName: null,
            winnerNextMatchID: null,
            loserNextMatchID: null,
            team1PreviousMatchID: null,
            team2PreviousMatchID: null,
            status: 0,
          };
          currentMatchIndex = currentMatchIndex + 1;
          return matchupObj;
        });
        return { matches };
      }
    );
    return losersBracketMatchupsAsObjectsArray;
  }

  //Figure out winners next and previous round IDs and set them
  const losersBracketMatchupsAsObjects = CreateLosersBracketInfoArray();
  let losersArrayFlat = [];
  losersBracketMatchupsAsObjects.forEach((round) => {
    losersArrayFlat.push(...round.matches);
  });
  function isOdd(num) {
    return num % 2 == 1;
  }
  const winnersBracketMatchupsWithInfo = winnersBracketMatchupsAsObjects.map(
    (round, roundIndex) => {
      const isLastRound =
        roundIndex === winnersBracketMatchupsAsObjects.length - 1;
      const matches = round.matches.map((match, matchIndex) => {
        const isByeRound =
          match.team1Name === "BYE" || match.team2Name === "BYE";
        const halfOfCurrentIndex = Math.floor(matchIndex / 2);
        const winnerNextMatchID = isLastRound
          ? null
          : halfOfCurrentIndex < 1
          ? winnersBracketMatchupsAsObjects[roundIndex + 1].matches[0].matchID
          : isOdd(halfOfCurrentIndex)
          ? winnersBracketMatchupsAsObjects[roundIndex + 1].matches[
              halfOfCurrentIndex
            ].matchID
          : winnersBracketMatchupsAsObjects[roundIndex + 1].matches[
              halfOfCurrentIndex - 1
            ].matchID;
        const loserNextMatchIndex = losersArrayFlat
          // .slice()
          // .reverse()
          .findIndex(
            (item) =>
              (match.team2Name === item.team1Name ||
                match.team2Name === item.team2Name) &&
              item.team2Name !== "BYE" &&
              item.team1Name !== "BYE"
          );
        const count = losersArrayFlat.length - 1;
        const correspondingLosersMatch = loserNextMatchIndex;
        if (correspondingLosersMatch > -1 && isByeRound !== true) {
          if (
            match.team2ID === losersArrayFlat[correspondingLosersMatch].team1ID
          ) {
            losersArrayFlat[correspondingLosersMatch].team1PreviousMatchID =
              match.matchID;
            losersArrayFlat[
              correspondingLosersMatch
            ].team1Name = `Loser of ${match.matchNumber}`;
            losersArrayFlat[correspondingLosersMatch].team1LoserOf =
              match.matchNumber;
          }
          if (
            match.team2ID === losersArrayFlat[correspondingLosersMatch].team2ID
          ) {
            losersArrayFlat[correspondingLosersMatch].team2PreviousMatchID =
              match.matchID;
            losersArrayFlat[
              correspondingLosersMatch
            ].team2Name = `Loser of ${match.matchNumber}`;
            losersArrayFlat[correspondingLosersMatch].team2LoserOf =
              match.matchNumber;
          }
        }

        let newMatchObj = {
          ...match,
        };
        newMatchObj.winnerNextMatchID = winnerNextMatchID;
        newMatchObj.loserNextMatchID = isByeRound
          ? null
          : losersArrayFlat[correspondingLosersMatch].matchID;
        return newMatchObj;
      });
      return matches;
    }
  );

  //Recreate Losers Bracket Info array with next match IDs, match Numbers, etc.
  let winnersBracketMatchGaps = [];
  winnersBracketMatchupsWithInfo.forEach((round, roundIndex) => {
    const isLastRound =
      roundIndex === winnersBracketMatchupsWithInfo.length - 1;
    const minMatchNum = isLastRound
      ? null
      : Math.min.apply(
          Math,
          winnersBracketMatchupsWithInfo[roundIndex + 1].map(function (o) {
            return o.matchNumber;
          })
        );
    const maxMatchNum = Math.max.apply(
      Math,
      round.map(function (o) {
        return o.matchNumber;
      })
    );
    winnersBracketMatchGaps.push([maxMatchNum, minMatchNum]);
  });
  let currentWinnersMatchGapIndex = !shouldLosersR1FollowWinnersR1 ? 1 : 0;
  let exteriorMatchNumberRange = [];
  let losersBracketMatchupsWithInfo = losersBracketMatchupsAsObjects.map(
    (round, roundIndex) => {
      const isLastRound =
        roundIndex === losersBracketMatchupsAsObjects.length - 1;
      let matchesInOrderOfPriority = [];
      round.matches.forEach((matchup, matchIndex) => {
        //Get highest 'loser of' seed
        if (Math.max(matchup.team1LoserOf, matchup.team2LoserOf) > 0) {
          matchesInOrderOfPriority.push({
            highestSeed: Math.max(matchup.team1LoserOf, matchup.team2LoserOf),
            matchID: matchup.matchID,
          });
        }
      });
      let matchesInOrderOfPrioritySorted = matchesInOrderOfPriority.sort(
        (a, b) => a.highestSeed - b.highestSeed
      );
      //function that maps the matchNumbers to an array for this current round (or two)
      function range(start, end) {
        return new Array(end - start).fill().map((d, i) => i + start);
      }
      let interiorMatchNumberRange = isLastRound
        ? [winnersBracketMatchGaps[currentWinnersMatchGapIndex][0] + 1]
        : range(
            winnersBracketMatchGaps[currentWinnersMatchGapIndex][0] + 1,
            winnersBracketMatchGaps[currentWinnersMatchGapIndex][1]
          );
      if (
        matchesInOrderOfPrioritySorted.length > interiorMatchNumberRange.length
      ) {
        const difference =
          matchesInOrderOfPrioritySorted.length -
          interiorMatchNumberRange.length;
        const arrayWithDifferenceIn0s = Array.from(
          { length: difference },
          (_, i) => 0
        );
        interiorMatchNumberRange = [
          ...interiorMatchNumberRange,
          ...arrayWithDifferenceIn0s,
        ];
      }
      const matches = round.matches.map((match, matchIndex) => {
        const newMatchObj = {
          ...match,
        };
        if (match.team1Name === "BYE" || match.team2Name === "BYE") {
          newMatchObj.matchNumber = null;
          return newMatchObj;
        } else {
          if (exteriorMatchNumberRange.length === 0) {
            exteriorMatchNumberRange = interiorMatchNumberRange;
          }
          const gapIndex = matchesInOrderOfPrioritySorted.findIndex(
            (item) => item.matchID === match.matchID
          );
          if (gapIndex > -1) {
            newMatchObj.matchNumber = exteriorMatchNumberRange[gapIndex];
            matchesInOrderOfPrioritySorted.splice(gapIndex, 1);
            exteriorMatchNumberRange.splice(gapIndex, 1);
          } else if (gapIndex === -1 && roundIndex > 0) {
            newMatchObj.matchNumber =
              exteriorMatchNumberRange[exteriorMatchNumberRange.length - 1];
            matchesInOrderOfPrioritySorted.splice(
              exteriorMatchNumberRange.length - 1,
              1
            );
            exteriorMatchNumberRange.splice(
              exteriorMatchNumberRange.length - 1,
              1
            );
          }
          if (exteriorMatchNumberRange.length < 1 && !isLastRound) {
            currentWinnersMatchGapIndex = currentWinnersMatchGapIndex + 1;
          }
          return newMatchObj;
        }
      });
      return matches;
    }
  );

  console.log("losersBracketMatchupsWithInfo: ", losersBracketMatchupsWithInfo);

  const losersBracketMatchupsWithFinalInfo = losersBracketMatchupsWithInfo.map(
    (round, roundIndex) => {
      const isLastRound =
        roundIndex === losersBracketMatchupsWithInfo.length - 1;
      const matches = round.map((match, matchIndex) => {
        console.log("match: ", match);
        const halfOfCurrentIndex = Math.floor(matchIndex / 2);
        const isNextRoundEqualSize = isLastRound
          ? false
          : losersBracketMatchupsWithInfo[roundIndex + 1].length ===
            round.length;
        const winnerNextMatch = isLastRound
          ? null
          : isNextRoundEqualSize
          ? losersBracketMatchupsWithInfo[roundIndex + 1][matchIndex]
          : halfOfCurrentIndex < 1
          ? losersBracketMatchupsWithInfo[roundIndex + 1][0]
          : isOdd(halfOfCurrentIndex)
          ? losersBracketMatchupsWithInfo[roundIndex + 1][halfOfCurrentIndex]
          : losersBracketMatchupsWithInfo[roundIndex + 1][
              halfOfCurrentIndex - 1
            ];
        const losersNextMatchIndex = isLastRound
          ? null
          : losersBracketMatchupsWithInfo[roundIndex + 1].findIndex(
              (item) => item.matchID === winnerNextMatch.matchID
            );

        console.log("winnerNextMatch: ", winnerNextMatch);
        let newMatchObj = {
          ...match,
        };
        if (winnerNextMatch && !isLastRound) {
          newMatchObj.winnerNextMatchID = winnerNextMatch.matchID;
        }
        if (
          winnerNextMatch &&
          !winnerNextMatch.team1PreviousMatchID &&
          losersNextMatchIndex
        ) {
          losersBracketMatchupsWithInfo[
            losersNextMatchIndex
          ].team1PreviousMatchID = match.matchID;
        } else if (
          winnerNextMatch &&
          !winnerNextMatch.team2PreviousMatchID &&
          losersNextMatchIndex
        ) {
          losersBracketMatchupsWithInfo[
            losersNextMatchIndex
          ].team2PreviousMatchID = match.matchID;
        }
        return newMatchObj;
      });
      return matches;
    }
  );

  // console.log("shouldLosersR1FollowWinnersR1: ", shouldLosersR1FollowWinnersR1);
  // console.log("losersArrayFlat: ", losersArrayFlat);
  // console.log("winnersArrayFlat: ", winnersArrayFlat);
  // console.log("winnersBracketMatchups: ", winnersBracketMatchups);
  // console.log(
  //   "winnersBracketMatchupsWithInfo: ",
  //   winnersBracketMatchupsWithInfo
  // );
  // console.log(
  //   "losersBracketMatchupsAsObjects: ",
  //   losersBracketMatchupsAsObjects
  // );
  console.log(
    "losersBracketMatchupsWithFinalInfo: ",
    losersBracketMatchupsWithFinalInfo
  );

  function doesMatchupIncludesByes(matchup, phrase) {
    // console.log("matchup: ", matchup);
    let containsPhrase = false;
    if (matchup.team1Name === phrase || matchup.team2Name === phrase) {
      containsPhrase = true;
    }
    return containsPhrase;
  }

  return (
    <Layout>
      <div
        style={{
          padding: 50,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          alignContent: "flex-start",
          justifyContent: "flex-start",
          // border: "1px solid black",
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: 50,
            // border: "1px solid black",
          }}
        >
          {/* {winnersBracketMatchups.map((round, roundIndex) => { */}
          {/* {winnersBracketMapped.map((round, roundIndex) => { */}
          {winnersBracketMatchupsAsObjects.map((round, roundIndex) => {
            return (
              <div
                style={{
                  display: "flex",
                  // border: "1px solid black",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "stretch",
                }}
              >
                {round.matches.map((matchup, index) => {
                  // console.log("matchup ", matchup);
                  // console.log("index % 2 === 0: ", index % 2 === 0);
                  return (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {roundIndex === 0 ? null : (
                        <div
                          style={{
                            height: 1,
                            width: 10,
                            backgroundColor:
                              roundIndex === 1 &&
                              !doesMatchupIncludesByes(matchup, "EMPTY")
                                ? "transparent"
                                : "#000",
                          }}
                        ></div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            paddingRight: 3,
                            width: 35,
                            textAlign: "right",
                          }}
                        >
                          {matchup.matchNumber}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            padding: 5,
                            margin:
                              roundIndex === 0 &&
                              !doesMatchupIncludesByes(matchup, "BYE")
                                ? "5px 0"
                                : "5px 0",
                            border: "1px solid #d2d2d2",
                            borderRadius: 7,
                            flexDirection: "column",
                            width: 150,
                            height: doesMatchupIncludesByes(matchup, "BYE")
                              ? 67
                              : 67,
                            opacity: doesMatchupIncludesByes(matchup, "BYE")
                              ? "30%"
                              : "100%",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 14,
                              backgroundColor: "#f2f2f2",
                              padding: 4,
                              borderRadius: 4,
                              marginBottom: 2,
                            }}
                          >
                            {/* {matchup[0] ? matchup[0] : "BYE"} */}
                            {matchup.team1Name}
                          </span>
                          <span
                            style={{
                              fontSize: 14,
                              backgroundColor: "#f2f2f2",
                              padding: 4,
                              borderRadius: 4,
                            }}
                          >
                            {/* {matchup[1] ? matchup[1] : "BYE"} */}
                            {matchup.team2Name}
                          </span>
                        </div>
                      </div>
                      {roundIndex === winnersBracketMatchups.length - 1 ||
                      doesMatchupIncludesByes(matchup, "BYE") ? null : (
                        <div
                          style={{
                            // border: "1px solid black",
                            // width: 5,
                            display: "flex",
                            flex: 1,
                            height: "100%",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid black",
                              width: 10,
                              display: "flex",
                              height: "100%",
                              borderLeft: 0,
                              borderTop: 0,
                              borderRight:
                                index % 2 === 0 ? 0 : "1px solid black",
                              borderBottom:
                                index % 2 === 0 ? 0 : "1px solid black",
                            }}
                          ></div>
                          <div
                            style={{
                              border: "1px solid black",
                              width: 10,
                              display: "flex",
                              height: "100%",
                              borderLeft: 0,
                              borderBottom: 0,
                              borderRight:
                                index % 2 === 1 ? 0 : "1px solid black",
                              borderTop:
                                index % 2 === 1 ? 0 : "1px solid black",
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div style={{ display: "flex" }}>
          {losersBracketMatchupsWithInfo.map((round, roundIndex) => {
            // {losersBracketFinalMatchupsAutoMapped.map((round, roundIndex) => {
            return (
              <div
                style={{
                  display: "flex",
                  // border: "1px solid black",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "stretch",
                }}
              >
                {round.map((matchup, index) => {
                  // console.log("matchup ", matchup);
                  // console.log("index % 2 === 0: ", index % 2 === 0);
                  return (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {roundIndex === 0 ? null : (
                        <div
                          style={{
                            height: 1,
                            width: 10,
                            backgroundColor:
                              // roundIndex === 1 && !matchup.includes("EMPTY")
                              //   ? "transparent"
                              //   :
                              "#000",
                          }}
                        ></div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            paddingRight: 3,
                            width: 35,
                            textAlign: "right",
                          }}
                        >
                          {matchup.matchNumber}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            padding: 5,
                            margin:
                              roundIndex === 0 &&
                              doesMatchupIncludesByes(matchup, "BYE")
                                ? "5px 0"
                                : "5px 0",
                            border: "1px solid #d2d2d2",
                            borderRadius: 7,
                            flexDirection: "column",
                            width: 150,
                            height: doesMatchupIncludesByes(matchup, "BYE")
                              ? 67
                              : 67,
                            opacity: doesMatchupIncludesByes(matchup, "BYE")
                              ? "30%"
                              : "100%",
                            // opacity: matchup.every((v) => v === "BYE")
                            //   ? "30%"
                            //   : "100%",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 14,
                              backgroundColor: "#f2f2f2",
                              padding: 4,
                              borderRadius: 4,
                              marginBottom: 2,
                            }}
                          >
                            {matchup.team1Name}
                          </span>
                          <span
                            style={{
                              fontSize: 14,
                              backgroundColor: "#f2f2f2",
                              padding: 4,
                              borderRadius: 4,
                            }}
                          >
                            {matchup.team2Name}
                          </span>
                        </div>
                      </div>
                      {(roundIndex !==
                        losersBracketFinalMatchupsAutoMapped.length - 1 &&
                        round.length ===
                          losersBracketFinalMatchupsAutoMapped[roundIndex + 1]
                            .length) ||
                      doesMatchupIncludesByes(matchup, "BYE") ? null : (
                        <div
                          style={{
                            // border: "1px solid black",
                            // width: 5,
                            display: "flex",
                            flex: 1,
                            height: "100%",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid black",
                              width: 10,
                              display: "flex",
                              height: "100%",
                              borderLeft: 0,
                              borderTop: 0,
                              borderRight:
                                index % 2 === 0 ? 0 : "1px solid black",
                              borderBottom:
                                index % 2 === 0 ? 0 : "1px solid black",
                            }}
                          ></div>
                          <div
                            style={{
                              border: "1px solid black",
                              width: 10,
                              display: "flex",
                              height: "100%",
                              borderLeft: 0,
                              borderBottom: 0,
                              borderRight:
                                index % 2 === 1 ? 0 : "1px solid black",
                              borderTop:
                                index % 2 === 1 ? 0 : "1px solid black",
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default TestTourney;
